<template>
    <div id="cont_Main" class="">
        <div class="main-content 
                    flex-1 
                    bg-gray-100 
                    mt-12 md:mt-12">
            <div class="bg-gray-800 pt-3">
                <div
                    class="
                    rounded-tl-3xl
                    bg-gradient-to-r
                    from-blue-900
                    to-gray-800
                    p-4
                    shadow
                    text-2xl text-white
                    "
                >
                <router-link to="/Cartera/PasePedidos" class="absolute block transform-gpu transition ease-in-out hover:scale-105 duration-300 z-10">
                    <ul class="list-reset">
                        <li>
                            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-10">
                                <path fill="currentColor"
                                      d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"/>
                            </svg>
                        </li>
                    </ul>      
                </router-link>
                <h3 class=" relative inline-block font-bold pl-2 font-sans">Pase de Pedidos</h3>
                </div>
            </div>
            <div id="cont_ppal" class="w-full md:w-10/12 mx-auto p-3 bg-white shadow-xl rounded-b-md z-10">
                <div id="cont_id" 
                    class="">
                    <div id="cont_op_CRZ" v-if="!esGZ"
                        class="mb-1
                        mx-auto">
                        <div id="cont_Corte" 
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-left">Cortes: </span> 
                            <select
                                id="buscarcorte"
                                v-model="buscarCorte"
                                class="border rounded-xl shadow h-9 bg-white w-full"
                                @change="getPase(buscarCorte,'N','N')"
                            >
                                <option disabled selected>Cortes</option>
                                <option v-for="(corte, index) in cortes" :value="corte.Corte" :key="index">
                                    {{ corte.Corte }}
                                </option>
                            </select>
                        </div>
                        <div id="cont_Region" 
                            class="relative 
                                    inline-block"
                            v-if="esRegional == false">
                            <span class=" relative block w-full text-left">Regiones: </span> 
                            <select
                                id="buscarregion"
                                v-model="buscarRegion"
                                class="border rounded-xl shadow h-9 bg-white w-full"
                                @change="getPase('N',buscarRegion,'N')"
                            >
                                <option disabled selected>Regiones</option>
                                <option v-for="(region, index) in regiones" :value="region.Region" :key="index">
                                    {{ region.Region }}
                                </option>
                            </select>
                        </div>
                        <div id="cont_zona" 
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-left">Zonas: </span> 
                            <select
                                id="buscarzona"
                                v-model="buscarZona"
                                class="border rounded-xl shadow h-9 bg-white w-full"
                                @change="getPase('N','N',buscarZona)"
                            >
                                <option disabled selected>Zonas</option>
                                <option v-for="(zona, index) in zonas" :value="zona.Zona" :key="index">
                                    {{ zona.Zona }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div id="cont_op_CRZ" v-if="!esGZ"
                        class="mb-1
                        mx-auto">
                        <div id="cont_Corte" v-if="filtroActual != 'N'"
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-center"><span class="font-bold"> {{"Filtro Actual: "}} </span> {{filtroActual}}</span>
                        </div>
                    </div>
                    <div id="cont_Pase relative" 
                         class=" rounded-lg overflow-hidden border border-gray-300 overflow-x-scroll">
                        <table class="table_GridPase  table-auto w-min md:w-full">
                            <thead class=" relative block bg-blue-900  text-white border-collapse">
                                <tr class="grid grid-flow-col auto-cols-auto relative table mx-auto pr-2">
                                    <th class=" row_tabla_pase w-16">Corte</th>
                                    <th class=" row_tabla_pase w-12 py-2">Región</th>
                                    <th class=" row_tabla_pase w-16 py-2">Zona</th>
                                    <th class=" row_tabla_pase w-16 py-2">Sección</th>
                                    <th class=" row_tabla_pase w-24 py-2">Cedula</th>
                                    <th class=" row_tabla_pase w-44 py-2">Nombre</th>
                                    <th class=" row_tabla_pase w-24 py-2">Teléfono</th>
                                    <th class=" row_tabla_pase w-16 py-2">Estado</th>
                                    <th class=" row_tabla_pase w-24 py-2">Cupo</th>
                                    <th class=" row_tabla_pase w-16 py-2">Puntos</th>
                                    <th class=" row_tabla_pase w-24 py-2">Val.Pedido</th>
                                    <th class=" row_tabla_pase w-20 py-2">Fact.</th>
                                    <th class=" row_tabla_pase w-44 py-2">Retiene</th>
                                </tr>
                            </thead>
                            <tbody class=" grid relative block overflow-y-scroll overflow-x-auto max-h-96">
                                <tr class="grid grid-flow-col auto-cols-auto relative border border-t-gray-300 table " v-for=" (item,index) in Pase" :key="index">
                                    <td class=" row_tabla_pase w-16">{{ item.corte}}</td>
                                    <td class=" row_tabla_pase w-12 py-2">{{ item.region }}</td>
                                    <td class=" row_tabla_pase w-16 py-2">{{ item.zona }}</td>
                                    <td class=" row_tabla_pase w-16 py-2">{{ item.seccion }}</td>
                                    <td class=" row_tabla_pase w-24 py-2">{{ item.cedula }}</td>
                                    <td class=" row_tabla_pase w-44 py-2">{{ item.nombre }}</td>
                                    <td class=" row_tabla_pase w-24 py-2">{{ item.telefono }}</td>
                                    <td class=" row_tabla_pase w-16 py-2">{{ item.estado }}</td>
                                    <td class=" row_tabla_pase w-24 py-2">{{ item.cupo }}</td>
                                    <td class=" row_tabla_pase w-16 py-2">{{ item.puntos }}</td>
                                    <td class=" row_tabla_pase w-24 py-2">{{ item.valPedido }}</td>
                                    <td class=" row_tabla_pase w-20 py-2">{{ item.tipoFact }}</td>
                                    <td class=" row_tabla_pase w-44 py-2 cont_cuadro_retiene">
                                        <div class="cont_Retiene w-11/12 mx-auto bg-blue-900 rounded-md px-2 py-1">
                                            <ul class="text-white font-bold text-left">
                                                <li v-if="item.retSaldo">{{ "Saldo: "}}<span class="font-normal">{{item.saldo}}</span></li>
                                                <li v-if="item.sinVerificar">{{ "Sin Verificar" }}</li>
                                                <li v-if="item.retTopeMax">{{ "Tope Máximo" }}</li>
                                                <li v-if="item.retTopeMin">{{ "Tope Mínimo" }}</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="cont_resumen" 
                    class="mt-3
                            mx-auto">
                        <div id="cont_cedula" 
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-left">Retenidos: </span> 
                            <input type="number" 
                                id="Cedula" 
                                v-model="pedidosRetenidos"
                                class="w-full
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md
                                        text-center" 
                                placeholder="Retenidos..." disabled>
                        </div>
                        <div id="cont_Nombre" 
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-left">Procesados:</span> 
                            <input type="text" 
                                id="NombreCliente" 
                                v-model="pedidosProcesados" 
                                class="w-full
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md
                                        text-center" 
                                placeholder="Procesados..." disabled>
                        </div>
                        <div id="cont_Nombre" 
                            class="relative 
                                    inline-block">
                            <span class=" relative block w-full text-left">Refrescar: </span> 
                            <button id="btn_Historico"
                                    class="flex
                                        w-full
                                        bg-blue-900
                                        rounded-lg
                                        text-white
                                        font-bold
                                        col-span-1
                                        py-2"
                                    @click="getPase('N','N','N')">
                                <i class="fas fa-sync-alt mx-auto my-auto"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import auth from "@/logic/auth";
import { mapState, mapMutations } from 'vuex'
export default{
    data(){
        return {
            Cedula: undefined,
            NombreCliente: "",
            ZonaCliente: "",
            Pase: [],
            pedidosRetenidos: 0,
            pedidosProcesados: 0,

            cortes: [],
            regiones: [],
            zonas: [],

            buscarCorte: "N",
            buscarRegion: "N",
            buscarZona: "N",

            esGZ: false,
            esRegional: false,

            filtroActual: "N",
        }
    },
    methods: {
        ...mapMutations([
            "loadNavbar", 
            "hiddenNavbar", 
            "logueo", 
            "closeNavInicio", 
            "closeNavCasos", 
            "openNavCobranza",
            "closeModalChatObservComp",
            "openModalInfoCliente",
            "openModalCargando",
            "closeModalCargando"
        ]),
        getPase(corte, region, zona) {
            this.openModalCargando()
            var filtroActualTemp
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            //console.log(user)

            this.cortes = user.parametrosPase.Cortes
            this.regiones = user.parametrosPase.Regiones
            this.zonas = user.parametrosPase.Zonas
            
            if(user.loginUsuario.substring(0,1) == '0'){
                this.esGZ = true
            }else{
                this.esGZ = false
            }
            if(user.loginUsuario.substring(0,3) == 'reg' || user.loginUsuario.substring(0,3) == 'Reg' || user.loginUsuario.substring(0,3) == 'REG'){
                this.esRegional = true
            }else{
                this.esRegional = false
            }
            if(corte == "N"){
                if(region == "N"){
                    if(zona == "N"){
                        filtroActualTemp = this.filtroActual
                        this.filtroActual = "N"
                    }else{
                        filtroActualTemp = this.filtroActual
                        this.filtroActual = "Zona " + zona
                    }
                }else{
                    filtroActualTemp = this.filtroActual
                    this.filtroActual = "Region " + region
                }
            }else{
                filtroActualTemp = this.filtroActual
                this.filtroActual = corte
            }
            axios
                .get(this.$hostname + "/Api/PasePedidos/GetPasePedidosSP/" + user.loginUsuario + 
                    "/" + corte +
                    "/" + region + 
                    "/" + zona, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {                                            
                    if(result.data.length > 0){
                        this.Pase = result.data
                        this.pedidosRetenidos = this.Pase[0].pedidosRetenidos
                        this.pedidosProcesados = this.Pase[0].pedidosProcesados

                        //console.log(this.esRegional)

                        this.buscarCorte = "N"
                        this.buscarRegion = "N"
                        this.buscarZona = "N"
                    }else{
                        this.buscarCorte = "N"
                        this.buscarRegion = "N"
                        this.buscarZona = "N"

                        this.filtroActual = filtroActualTemp

                        if(corte != 'N'){
                            this.$alert(
                                "El " + corte + " no tiene pedidos retenidos!",
                                "Sin Retenidos!",
                                "warning"
                            );
                        }

                        if(region != 'N'){
                            this.$alert(
                                "La region " + region + " no tiene pedidos retenidos!",
                                "Sin Retenidos!",
                                "warning"
                            );
                        }

                        if(zona != 'N'){
                            this.$alert(
                                "La zona " + zona + " no tiene pedidos retenidos!",
                                "Sin Retenidos!",
                                "warning"
                            );
                        }
                    }

                    this.closeModalCargando()
                })
                .catch((err) => {
                    console.log(err);
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.closeModalCargando()
                    this.$router.push("/");
                });
        },
    },
    created() {
        this.getPase('N','N','N')
    },
    computed: {
        ...mapState(["modalChatObservComp","modalInfoCliente","permisosUsuario"])
    }
}
</script>

<style>
*:focus {
    outline: 0 !important;
}
input {
    padding: 2px;
}
button{
    transition: 0.2s ease-in-out;
}
button:hover{
    transform: scale(1.05);
}
td.w-full.py-2 {
    align-self: center;
}
.table_GridPase{
    min-width: 1100px;
}
#cont_resumen div {
    width: 20%;
    height: 60px;
    margin: 5px 20px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
}
#cont_op_CRZ div {
    width: 20%;
    margin: 5px 20px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
}
div#cont_resumen {
    display: table;
}
.cont_cuadro_retiene {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.row_tabla_pase {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
thead  ::-moz-selection,
.cont_Retiene ::-moz-selection { /* Code for Firefox */
  color: #1e3a8a;
  background: #fff;
}
thead  ::selection,
.cont_Retiene ::selection {
  color: #1e3a8a;
  background: #fff;
}
</style>